import React from 'react'


const SSPVIndustryTow = ({ page }) => (
  <section className="ss_pv_two ss_section_sec_bg spacer_bottom spacer_top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 col-lg-5">
          <div className="ss_pv_two_left">
            <img className="img-fluid" src={page.wrapper} alt="Image" />
          </div>
        </div>
        <div className="col-xl-6 col-lg-7">
          <div className="ss_pv_two_right">
            <h3>{page.label}</h3>
            <h1 className="mb-4">{page.heading}</h1>
            <div id="counter" className="pv_value">
              {page.blocks.map((block, i) => (
                <div className="ss_box_bg text-center">
                  <h2>
                    <span><div className="ss_count counter-value">{block.number}</div></span>
                    {block.label}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SSPVIndustryTow;

import React from 'react'


const SSPVIndustryOne = ({
  page,
}) => (
  <section className="ss_pv_one spacer_top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-5">
          <div className="ss_pv_left spacer_bottom">
            <h3>{page.label}</h3>
            <h1>{page.heading}</h1>
            <p className="mt-3 mb-4">{page.description}</p>
            {page.articles.map((art, i) => (
              <div className="ss_box_bg mb-4">
                <div className="row">
                  <div className="col-sm-5 align-self-center">
                    <img className="img-fluid" src="/assets/images/ss_pv_one.png" alt="image" />
                  </div>
                  <div className="col-sm-7">
                    <div className="ss_pv_l_content">
                      <h2>{art.heading}</h2>
                      <p>{art.description}</p>
                      <a href={art.button.link}>{art.button.label} &gt;&gt;</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>	
        </div>	
        <div className="col-lg-7">
          <div className="ss_pv_right">
            <img className="img-fluid" src="/assets/images/ss_pv_three.png" alt="image" />
          </div>	
        </div>	
      </div>	
    </div>
  </section>
);

export default SSPVIndustryOne;

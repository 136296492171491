import React from 'react'
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Header from 'components/Header';
import Link from 'components/Link';
import SSPVIndustryOne from 'components/Sections/SSPVIndustryOne';
import SSPVIndustryTow from 'components/Sections/SSPVIndustryTow';
import i18nx from 'components/locale/i18nx';


const OurWorkPage = ({
  data: {markdownRemark: { frontmatter }},
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={frontmatter.title}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.title}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / {frontmatter.title}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <SSPVIndustryOne page={frontmatter.section_one} />
      <SSPVIndustryTow page={frontmatter.section_tow} />
    </Layout>
  )
}


export const pageQuery = graphql`
  query OurWork($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "our-work"}}) {
      frontmatter {
        title
        section_one {
          label
          heading
          description
          articles {
            description
            heading
            image
            button {
              label
              link
            }
          }
        } 
        section_tow {
          label
          heading
          wrapper
          blocks {
            image
            label
            number
          }
        } 
      }
    }
  }
`

export default OurWorkPage;
